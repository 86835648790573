import React, { useState, useEffect } from 'react';
import "../styles/Ncontactus2.css";
import Layout from "../components/Layout/Layout";
import logo from "../assets/abaskologo.png";
import Hlogo from "../assets/hypernxt_logo.png";


const EnquiryForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Replace with your Google Apps Script URL
        fetch('https://script.google.com/macros/s/YOUR_SCRIPT_ID/exec', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => {
                if (response.ok) {
                    setSuccess(true);
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        message: ''
                    });
                } else {
                    alert('Form submission failed. Please try again.');
                }
            })
            .catch(error => {
                alert('Error submitting form: ' + error.message);
            });
    };

    useEffect(() => {
        const formContainer = document.getElementById('formContainer');
        setTimeout(() => {
            formContainer.classList.add('animate');
        }, 500);
    }, []);

    return (
        <>
        <Layout>
            {/* Bootstrap CDN */}
            <link
                rel="stylesheet"
                href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
            />

            <div className="container-fluid">
                <div className="row">
                    {/* Graphics Section */}
                    <div className="col-lg-6 graphics-container d-none d-lg-flex">
                        <div>
                            <img src={logo} alt="Education Graphic" className="img-fluid" />
                            <h1>Empowering Education</h1>
                            <p>Join us to enhance your knowledge and skills for a brighter future!</p>
                        </div>
                    </div>

                    {/* Form Section */}
                    <div className="col-lg-6 d-flex justify-content-center align-items-center" style={{ paddingTop: '50px' }}>
                        <div className="form-container" id="formContainer">
                            <h2 className="text-center" style={{width: '350px'}}>Contact Us</h2>
                            <form id="educationForm" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Phone No</label>
                                    <input type="tel" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} placeholder="Enter your phone number" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <input type="text" className="form-control" id="message" name="message" value={formData.message} onChange={handleChange} placeholder="Enter your message" required />
                                </div>
                                <button type="submit" className="btn btn-primary btn-block">Submit</button>
                            </form>
                            {/* Success Popup */}
                            {success && (
                                <div id="successPopup">
                                    <img src="https://via.placeholder.com/50x50.png?text=✓" alt="Success" />
                                    <h4>Thank you!</h4>
                                    <p>Your form has been submitted successfully.</p>
                                </div>
                            )}
                            {/* Powered By Section */}
                            <div className="powered-by" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                                Powered by
                                <a href="https://thehypernxt.com" target="_blank" rel="noopener noreferrer">
                                    <img src={Hlogo} alt="YourCompany Logo" style={{width: '100px'}} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Separator */}
                <div className="separator"></div>
            </div>
            </Layout>
        </>
    );
};

export default EnquiryForm;
