
export const leadingCourses = [
  {
    course:"MERN",
    head:"IIT JEE",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
    link:"/mern_program"
  },
  {
    course:"NEET",
    head:"NEET",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
    link:"/mean_program"
  },
  {
    course:"CA",
    head:"CA",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
  },
  {
    course:"UPSE",
    head:"UPSE ",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
  },
  {
    course:"NISER",
    head:"NISER",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
  },
  {
    course:"AIIMS",
    head:"AIIMS",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
  },
]