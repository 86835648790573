/** @format */

import React from "react";
import "../Footer/Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillInstagram } from "react-icons/ai";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import freedemologo from "../../assets/freedemologo.png";
import footerlogo from "../../assets/foooterimage.png";
import { Link } from "react-router-dom";
const Footer = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling effect
    });
  };
  return (
    <>
      <div className="footer-container">
        {/* topside */}
        <div className="ccntr">
        <div className="free-demo-card">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              gap: "20px",
              paddingTop: "40px",
            }}
          >
            <div style={{ marginTop: "20px" }}>
              {/* <Typography
                style={{ color: "#000000", fontSize: "50px", width: "300px" }}
              >
                GET A <span style={{ color: "#066CD3" }}>FREE DEMO CLASS</span>{" "}
                NOW
              </Typography> */}
              <h2
                style={{ color: "#000000", fontSize: "30px", width: "300px" , fontFamily: "Martel Sans", lineHeight: "30px", marginBottom: "50px"}}
              >
                GET A <span style={{ color: "#066CD3", fontFamily: "Martel Sans" }}>FREE DEMO CLASS</span>{" "}
                NOW
              </h2>
              <div>
                <p style={{}}>For more queries</p>
                <div className="input-container">
                  <TextField
                    variant="outlined"
                    placeholder="Enter phone number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ backgroundColor: "blue", color: "#fff" }}
                          >
                            <SendIcon style={{width: "15px", height: "15px"}} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    className="textfree-demo"
                  />
                </div>
              </div>
            </div>
            <div>
              <img
                src={freedemologo}
                style={{ width: "220px", height: "220px", objectFit: "cover" }}
                className="freedemo-images"
              />
            </div>
          </div>
        </div>
        </div>
        {/* bottom footer */}
        <div className="footerbottom-main">
          <div className="footerbottom-text-main">
            <div>
              <img src={footerlogo} className="footerbottom-mainimage" />
            </div>
            <div className="footerbottom-secondone">
              <ul className="footerbottom-ulisecond">
                <li className="footerbottom-lisecond first-li">Support</li>
                <li className="footerbottom-lisecond">Getting started</li>
                <li className="footerbottom-lisecond">Help center</li>
                <li className="footerbottom-lisecond">Report a bug</li>
              </ul>
            </div>
            <div className="footerbottom-3text">
              <ul className="footerbottom-ulthird">
                <li className="footerbottom-li3 first-li2">Legal</li>
                <li className="footerbottom-li3">
                  <Link
                    to="/privacypolicy"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="footerbottom-li3">
                  <Link
                    to="/cookiespolicy"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Cookie Policy
                  </Link>
                </li>
                <li className="footerbottom-li3">
                  <Link
                    to="/termscondition"
                    style={{ textDecoration: "none", color: "#fff" }}
                    onClick={handleClick}
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li className="footerbottom-li3">
                  <Link
                    to="/refundpolicy"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Refund Policy
                  </Link>
                </li>
                <li className="footerbottom-li3">
                  <Link
                    to="/shippingpolicy"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Shipping Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footerbottom-threetext">
              <ul className="footerbottom-downloadul">
                <li className="download-li downli2">Downloads</li>
                <li className="download-li">iOS</li>
                <li className="download-li">Android</li>
                <li className="download-li">Mac</li>
                <li className="download-li">Windows</li>
              </ul>
            </div>
            <div className="newsletter-bodyfooter">
              <p className="newfoot-subtext">Subscribe to our newsletter</p>
              <p className="news-footertext">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Temporibus, iste?
              </p>
              <div className="main-newsfoor">
                <input className="input-sub" placeholder="Enter Your Email" />
                <button className="button-subnews">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
        <div className="footerdown-main">
          <h3 className="react-out-text">Reach out to us on</h3>
          <div className="footerdown-social">
            <FaFacebookF color="#fff" fontSize={23} />
            <IoLogoWhatsapp color="#fff" fontSize={23} />
            <AiFillInstagram color="#fff" fontSize={23} />
          </div>
          <h4 className="react-out-text-two">© Copyright 2024</h4>
        </div>
      </div>
    </>
  );
};
export default Footer;
