/** @format */

import React, { useState } from "react";
import "./Header.css";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { NavLink, Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import logo from "../../assets/abaskologo.png";
import logonew from "../../assets/logonew.png";

// ------------------------------------

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);
  // sidebar

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  // -------------

  const handleMoreOpen = () => {
    setMoreOpen(!moreOpen);
  };

  const handleDrawertoggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawertoggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          className="header_logo"
          sx={{
            flexGrow: 1,
            my: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NavLink to={"/"}>
            <img
              src={logonew}
              alt="logo"
              style={{ marginRight: "5px", width: "70px", height: "25px" }}
            />
          </NavLink>
        </Typography>
      </Box>
      <Divider />

      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          padding: "8px 0px",
        }}
        component="nav"
      >
        <ListItemButton href="/">
          <ListItemText
            primary={
              <Typography variant="body1" sx={{ fontSize: "18px" }}>
                Home{" "}
              </Typography>
            }
          />
        </ListItemButton>

        <ListItemButton href="/blogs">
          <ListItemText
            primary={
              <Typography variant="body1" sx={{ fontSize: "18px" }}>
                Blog
              </Typography>
            }
          />
        </ListItemButton>

        
        <ListItemButton href="/contactus">
          <ListItemText
            primary={
              <Typography variant="body1" sx={{ fontSize: "18px" }}>
                Contact Us
              </Typography>
            }
          />
        </ListItemButton>

        {/* <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton href={"/blog"} sx={{ pl: 3 }}>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontSize: "18px" }}>
                    Blog
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItemButton href={"/contact"} sx={{ pl: 3 }}>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontSize: "18px" }}>
                    Contact us
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItemButton href={"/gallery"} sx={{ pl: 3 }}>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontSize: "18px" }}>
                    Gallery
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItemButton href={"/news_events"} sx={{ pl: 3 }}>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontSize: "18px" }}>
                    News & Events
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItemButton href={"/hostworkshop"} sx={{ pl: 3 }}>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontSize: "18px" }}>
                    Host a Workshop
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
        </Collapse> */}

      </List>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#EEECEC",
          position: "fixed",
          width: "100%",
          height: "10vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "999",
        }}
      >
        <Box
          component="nav"
          sx={{
            width: "100%",
            height: "10vh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#EEECEC",
            padding: { md: "0px 35px", sm: "0px 10px" },
          }}
        >
          <Box className="logo">
            <Link to={"/"}>
              <img src={logonew} alt="logo" />
            </Link>
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{
              margin: "2",
              display: { xs: "block", sm: "none" },
              position: "absolute",
              right: "6px",
            }}
            onClick={handleDrawertoggle}
          >
            <MenuIcon color="primary" />
          </IconButton>

          <Box
            className="navigation_menu"
            sx={{
              display: { xs: "none", sm: "block", height: "10vh" },
              marginRight: { lg: "40px" },
            }}
          >
            <ul>
              <li>
                <NavLink
                  to={"/"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  style={({ isActive }) => ({
                    fontWeight: isActive ? "700" : "500",
                  })}
                >
                  Home
                </NavLink>
              </li>

              {/* <li><NavLink to={"/courses"} style={({ isActive }) => ({
                fontWeight: isActive ? '700' : '500',
              })}  >Courses</NavLink></li> */}

              <li><NavLink to={"/blogs"} style={({ isActive }) => ({
                fontWeight: isActive ? '700' : '500',
              })}  >Blogs</NavLink></li>
               <li><NavLink to={"/contactus"} style={({ isActive }) => ({
                fontWeight: isActive ? '700' : '500',
              })}  >Contact Us</NavLink></li>
              {/* <li>
                <NavLink
                  to={"/about"}
                  style={({ isActive }) => ({
                    fontWeight: isActive ? "700" : "500",
                  })}
                >
                  About
                </NavLink>
              </li> */}

              {/* <li className='nav_more'><NavLink  style={{position:'relative'}}>More
                <ArrowDropDownIcon sx={{ position: 'absolute', top: '2px' }} />
              </NavLink>

                <ul className='drop_down_menu'>
                  <li><Link to={"/blog"}><ArrowForwardIcon className='dropdown_arrow' />Blog</Link></li>

                  <li><Link to={"/contact"}><ArrowForwardIcon className='dropdown_arrow' />Contact Us</Link></li>

                  <li><Link to={"/gallery"}><ArrowForwardIcon className='dropdown_arrow' />Gallery</Link></li>

                  <li><Link to={"/news_events"}><ArrowForwardIcon className='dropdown_arrow' />News & Events</Link></li>

                  <li><Link to={"/hostworkshop"}><ArrowForwardIcon className='dropdown_arrow' />Host a Workshop</Link></li>
                </ul>
              </li> */}
            </ul>
          </Box>

          <Box
            sx={{ display: { xs: "none", sm: "block" } }}
            className="inq_box"
          >
            {/* <button>
              <Link to={"/enquire"}>Login</Link>
            </button> */}
          </Box>
        </Box>

        <Box component="nav">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen || moreOpen}
            onClose={handleDrawertoggle}
            sx={{
              display: { sx: "block", sm: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: "200px" },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

export default Header;
