import React from 'react';
import "./ourCources.css";
import CourcePoster from "../../assets/courcePoster.png";
import Star from "../../assets/Star 3.png";
import Enroll from "../../assets/image 57.png";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';


const OurCources = () => {
  return (
    <>
      <section className='ourCources'>
        <h1>Our Courses</h1>
        <p>Explore a variety of courses designed to enhance your skills and knowledge.</p>
        <div className="cources">
          <div className="cource">
            <img src={CourcePoster} alt="" />
            <h2>ABASKO ADVANCED JEE 3.0</h2>
            <h4>IIT-JEE, GATE Aspirants</h4>
            <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div>
            <div className="price">
              <span>₹40000 <div className='line'></div></span>
              <span>₹36000</span>
            </div>
            <Link to='/courses' ><Button variant="outlined">VIEW COURSE</Button></Link>
          </div>
          <div className="cource">
            <img src={CourcePoster} alt="" />
            <h2>ABASKO ADVANCED JEE 3.0</h2>
            <h4>IIT-JEE, GATE Aspirants</h4>
            <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div>
            <div className="price">
              <span>₹40000 <div className='line'></div></span>
              <span>₹36000</span>
            </div>
            <Link to='/courses' ><Button variant="outlined">VIEW COURSE</Button></Link>
          </div>
          <div className="cource">
            <img src={CourcePoster} alt="" />
            <h2>ABASKO ADVANCED JEE 3.0</h2>
            <h4>IIT-JEE, GATE Aspirants</h4>
            <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div>
            <div className="price">
              <span>₹40000 <div className='line'></div></span>
              <span>₹36000</span>
            </div>
            <Link to='/courses' ><Button variant="outlined">VIEW COURSE</Button></Link>
          </div>
          <div className="cource">
            <img src={CourcePoster} alt="" />
            <h2>ABASKO ADVANCED JEE 3.0</h2>
            <h4>IIT-JEE, GATE Aspirants</h4>
            <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div>
            <div className="price">
              <span>₹40000 <div className='line'></div></span>
              <span>₹36000</span>
            </div>
            <Link to='/courses' ><Button variant="outlined">VIEW COURSE</Button></Link>
          </div>
          <div className="cource">
            <img src={CourcePoster} alt="" />
            <h2>ABASKO ADVANCED JEE 3.0</h2>
            <h4>IIT-JEE, GATE Aspirants</h4>
            <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div>
            <div className="price">
              <span>₹40000 <div className='line'></div></span>
              <span>₹36000</span>
            </div>
            <Link to='/courses' ><Button variant="outlined">VIEW COURSE</Button></Link>
          </div>
          <div className="cource">
            <img src={CourcePoster} alt="" />
            <h2>ABASKO ADVANCED JEE 3.0</h2>
            <h4>IIT-JEE, GATE Aspirants</h4>
            <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div>
            <div className="price">
              <span>₹40000 <div className='line'></div></span>
              <span>₹36000</span>
            </div>
            <Link to='/courses' ><Button variant="outlined">VIEW COURSE</Button></Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurCources;
